<script lang="ts" setup>
    import { OffsetGroup } from '~/data/OffsetGroup'

    const props = defineProps<{
        block: MediaCallToActionBlock
    }>()

    const mediaOffset = OffsetGroup.random(true)
    const ctaOffset = mediaOffset.complementary
</script>

<template>
    <div class="block-gap-y layout-grid last:mb-0">
        <Asset v-if="!block.media_link" :asset="block.media" :class="mediaOffset.classes">
            {{ block.media.title }}
        </Asset>

        <NuxtLink v-else :to="block.media_link" :class="mediaOffset.classes" class="group">
            <Asset :asset="block.media">
                <div class="flex items-center justify-between">
                    {{ block.media.title }}
                    <Arrow
                        class="relative right-0 top-[-0.05em] group-hover:right-[-0.1em] group-hover:top-[-0.15em]"
                    />
                </div>
            </Asset>
        </NuxtLink>

        <div
            class="cta-wrapper block-gap-t flex items-center justify-center md:mt-0"
            :class="ctaOffset.classes"
        >
            <BlockCallToAction
                :block="block as CallToActionBlock"
                :grid="false"
                class="max-w-[220px] lg:max-w-[330px]"
            />
        </div>
    </div>
</template>

<style scoped>
    .cta-wrapper {
        position: relative;
        top: -20px;
    }
</style>
